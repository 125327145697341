import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { useParams, Link, useNavigate } from "react-router-dom";
import axios from "axios";
import API_URL from "../../config";

const PostImplement = () => {
	const { userId, userName } = useParams();
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const apiUrl = API_URL;
	const navigate = useNavigate();
	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			// Make a POST request to your backend API
			const response = await axios.post(`${apiUrl}/implements`, {
				title: title,
				description: description,
				userId: userId,
				userName: userName,
			});
			// Handle success
			console.log("Response from server:", response.data);
			// setRecords(prevRecords => [...prevRecords, response.data]);
			setTitle("");
			setDescription("");
			alert("Implement posted successfully!");
		} catch (error) {
			// Handle error
			console.error("Error occurred:", error);
		}
	};
	return (
		<Form onSubmit={handleSubmit}>
			<BackButton onClick={() => navigate(-1)}>Back</BackButton>
			<FormGroup>
				<Label>Title:</Label>
				<Input
					type="text"
					value={title}
					placeholder="Title of the Project"
					onChange={(e) => setTitle(e.target.value)}
					required
				/>
			</FormGroup>
			<FormGroup>
				<Label>Description:</Label>
				<Textarea
					value={description}
					placeholder="Description about the project"
					onChange={(e) => setDescription(e.target.value)}
					required
				/>
			</FormGroup>
			<Button type="submit">Submit</Button>
		</Form>
	);
};
const BackButton = styled.button`
	display: inline-block;
	background-color: #2e4052;
	color: white;
	padding: 10px 20px;
	text-decoration: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;
	&:hover {
		background-color: #34495e;
	}
`;

const Textarea = styled.textarea`
	width: 100%;
	height: 100px;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
`;

const Button = styled.button`
	padding: 8px 20px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	margin-top: 10px;
	&:hover {
		background-color: #0056b3;
	}
`;
const Form = styled.form`
	margin-bottom: 20px;
	margin-top: 20px;
	max-width: 70%;
	margin-left: auto;
	margin-right: auto;
`;
const FormGroup = styled.div`
	margin-bottom: 15px;
`;
const Label = styled.label`
	display: block;
	margin-bottom: 5px;
	font-weight: bold;
`;
const Input = styled.input`
	width: 100%;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
`;

export default PostImplement;
