import React from "react";
import styled from "styled-components";
import Navbar from "../../components/navbar";
import { Link } from "react-router-dom";
import Footer from "../../components/footer";

const Home = () => {
  return (
    <HomeContainer>
      <Navbar />
      <ContentContainer>
        <Heading>Welcome to Skill Swap</Heading>
        <Description>
          Skill Swap is a vibrant community where you can unleash your potential
          and collaborate with experts. We believe in exchanging skills,
          empowering individuals, and fostering personal growth.
        </Description>
        <ButtonContainer>
          <StyledLink to="/dashboard">Get Started</StyledLink>
        </ButtonContainer>
      </ContentContainer>
      <Footer />
    </HomeContainer>
  );
};

const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const ContentContainer = styled.div`
  flex-grow: 1;
  padding: 50px 20px;
  text-align: center;
  background-color: #fff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 20px;
  max-width: 800px;
  margin: auto;
  margin-top: 3rem;
`;

const Heading = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const Description = styled.p`
  font-size: 18px;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const ButtonContainer = styled.div`
  margin-top: 30px;
`;

const StyledLink = styled(Link)`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 6px;
  padding: 14px 40px;
  font-size: 18px;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #0056b3;
  }

  @media (max-width: 768px) {
    font-size: 16px;
    padding: 12px 30px;
  }
`;

export default Home;
