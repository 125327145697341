//pk_test_51NeK18SCNDOIGjztTERH4Ow1UwOfTy1OKieHLvVn3gHdMJyrhymax6hsA6dVoteWgA0GnYtqajxij7zZyVNEBlwJ00keKB9Q5T

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { loadStripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import API_URL from "../../config";

function SkillCoins() {
  const [amount, setAmount] = useState(0); // State to store the entered amount
  const apiUrl = API_URL;
  const [userID, setUserID] = useState("");
  const [skillCoins, setSkillCoins] = useState(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const publishableKey = "rzp_live_tt6hqX1ZTAeFwi"; //live mode

  useEffect(() => {
    setLoading(true);
    const fetchUserDetails = async () => {
      try {
        // Retrieve the JWT token from localStorage
        const token = localStorage.getItem("jwt");
        if (!token) {
          // Handle the case where the token is not present
          console.error("JWT token not found in localStorage");
          navigate("/register");
          return;
        }

        // Make a request to the backend with the JWT token in the Authorization header
        const response = await axios.get(`${apiUrl}/skill-coins`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        if (response.data.error === "not filled details") {
          navigate("/p");
        }
        setUserID(response.data.userID);
        setSkillCoins(response.data.skillCoins);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch user details:", error);
      }
    };

    fetchUserDetails();
  }, [navigate]);

  const handlePaymentSuccess = async (paymentResponse) => {
    try {
      console.log("paymentResponse", paymentResponse);

      //userID, skillCoinsQuantity, orderID
      const response = await axios.post(`${apiUrl}/payment-success`, {
        userID,
        amount,
        paymentID: paymentResponse.razorpay_payment_id,
        orderID: paymentResponse.razorpay_order_id,
        signature: paymentResponse.razorpay_signature,
      });

      if (response.data.success) {
        console.log("Payment successful! Skill coins balance updated.");
        navigate("/skill-coins/success");
      } else {
        console.log("response", response.data);
        console.log("paymentResponse", paymentResponse);
        console.log("Payment update failed. Please contact support.");
        console.log({
          userID,
          amount: paymentResponse.amount ? paymentResponse.amount : amount,
          paymentID: paymentResponse.razorpay_payment_id,
          orderID: paymentResponse.razorpay_order_id,
          signature: paymentResponse.razorpay_signature,
        });
        navigate("/skill-coins/failure");
      }
    } catch (error) {
      console.error("Error updating payment:", error);
      console.log(
        "An error occurred while updating payment. Please try again later."
      );
    }
  };

  const makePayment = async () => {
    try {
      // Convert amount to paisa
      const amountInPaisa = amount * 100; // Assuming amount is in rupees

      const response = await axios.post(`${apiUrl}/create-razorpay-order`, {
        amount: amountInPaisa, // Amount in paisa
      });

      const options = {
        key: publishableKey, // Replace with your Razorpay key
        amount: amountInPaisa, // Amount in paisa
        currency: "INR",
        order_id: response.data.id,
        name: "Skill Coins Purchase",
        description: "Skill Coins Purchase",
        handler: handlePaymentSuccess,
      };

      const razorpay = new window.Razorpay(options);
      razorpay.open();
    } catch (error) {
      console.error("Error initiating payment:", error);
    }
  };

  // const handlePaymentSuccess = async (paymentIntentID, chargeID) => {
  //   try {
  //     const skillCoinsQuantity = Math.floor(parseFloat(amount) / 10);

  //     // Send a POST request to your backend API to update user's balance
  //     const response = await fetch(
  //       `${apiUrl}/payment-success`, // Update with your live backend API URL
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           userID,
  //           skillCoinsQuantity,
  //           paymentIntentID,
  //           chargeID,
  //         }), // Convert amount to a floating-point number
  //       }
  //     );
  //     const result = await response.json();

  //     if (result.success) {
  //       // Payment update was successful
  //       console.log("Payment successful! Skill coins balance updated.");
  //     } else {
  //       // Payment update failed
  //       console.log("Payment update failed. Please contact support.");
  //     }
  //   } catch (error) {
  //     console.error("Error updating payment:", error);
  //     console.log(
  //       "An error occurred while updating payment. Please try again later."
  //     );
  //   }
  // };

  // const makePayment = async () => {
  //   console.log("came inside make payment");
  //   const stripe = await loadStripe(
  //     publishableKey // Use your live publishable key here
  //   );
  //   console.log("afeter stripe load");
  //   if (amount > 0 && amount % 10 === 0) {
  //     const body = { amount }; // Use the entered amount
  //     const headers = {
  //       "Content-Type": "application/json",
  //     };

  //     const response = await fetch(
  //       `${apiUrl}/create-checkout-session`, // Update with your live backend API URL
  //       {
  //         method: "POST",
  //         headers: headers,
  //         body: JSON.stringify(body),
  //       }
  //     );

  //     const session = await response.json();

  //     const result = stripe.redirectToCheckout({
  //       sessionId: session.paymentIntentID,
  //     });
  //     console.log("result", result);
  //     if (result.error) {
  //       console.log(result.error);
  //     } else {
  //       // Call handlePaymentSuccess after payment is successful
  //       handlePaymentSuccess(session.paymentIntentID, session.chargeID);
  //     }
  //   } else {
  //     alert("Please enter the amount as a multiples of 10");
  //   }
  // };

  return loading ? (
    <div>
      <CustomLoader>
        <LoaderSpinner />
      </CustomLoader>
    </div>
  ) : (
    <>
      <StyledSkillCoinsPage>
        <StyledCard>
          <ButtonHeader>
            <StyledBackButton to={`/dashboard/${userID}`}>Back</StyledBackButton>
            <StyledAboutButton to="/skill-coins/rules">
              About Skill Coins
            </StyledAboutButton>
          </ButtonHeader>
          <h3>Your balance: {skillCoins} skill coins</h3>
          <StyledCardBody>
            <h4>Enter Amount for Skill Coins:</h4>
            <h5>Currently the price of a single SkillCoin is Rs.10</h5>
            <StyledInput
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount"
            />
            <StyledButton to="#" onClick={makePayment}>
              Pay with Razorpay
            </StyledButton>
          </StyledCardBody>
        </StyledCard>
        {/* <CustomPurchaseLink href="http://localhost:3001" target="_blank">
          Custom Purchase Link
        </CustomPurchaseLink> */}
      </StyledSkillCoinsPage>
    </>
  );
}

const StyledSkillCoinsPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const StyledCard = styled.div`
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 15vh;
  margin-bottom: 20px;
  width: 100%;
  max-width: 400px;
`;

// const StyledBackButton = styled(Link)`
//   color: #333;
//   text-decoration: none;
//   margin-bottom: 10px;
//   font-weight: bold;
// `;

// const StyledAboutButton = styled(Link)`
//   color: #333;
//   text-decoration: none;
//   margin-bottom: 20px;
//   font-weight: bold;
// `;

const StyledCardBody = styled.div`
  margin-top: 20px;
`;

const ButtonHeader = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const StyledButton = styled.button`
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  display: block;
`;

const CustomPurchaseLink = styled.a`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  margin-top: 20px;
  display: block;
`;
const StyledBackButton = styled(Link)`
  background-color: #ffffff;
  color: #4caf50;
  border: 2px solid #4caf50;
  border-radius: 4px;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 5px;
  &:hover {
    background-color: #4caf50;
    color: #ffffff;
  }
`;

const StyledAboutButton = styled(Link)`
  background-color: #ffffff;
  color: #007bff;
  border: 2px solid #007bff;
  border-radius: 4px;
  padding: 10px 20px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s, color 0.3s;
  margin-top: 5px;

  &:hover {
    background-color: #007bff;
    color: #ffffff;
  }
`;
//loader
const CustomLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const LoaderSpinner = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default SkillCoins;
