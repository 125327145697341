import React, { useState } from "react";
import styled from "styled-components";

const Nav = styled.nav`
  background-color: #222;
  padding: 20px;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: ${({ open }) => (open ? "block" : "none")};
  z-index: 997; /* Lower z-index to ensure overlay is below content */
`;

const NavList = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
  transition: transform 0.5s ease-in-out;
  transform: translateY(${({ open }) => (open ? "0" : "-100%")});

  @media screen and (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) ${({ open }) => (open ? "" : "scaleY(0)")};
    background-color: #222;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    z-index: 998;
    width: 80%;
    max-width: 300px;
  }
`;

const NavItem = styled.li`
  margin-right: 20px;
  font-size: 18px;
  @media screen and (max-width: 768px) {
    margin: 10px 0;
    &:hover {
      background-color: #ff8800;
    }
  }
`;

const NavLink = styled.a`
  color: #fff;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
  margin-top: 10%;

  &:hover {
    background-color: #ff8800;
  }
`;

const Hamburger = styled.div`
  display: none;
  cursor: pointer;
  font-size: 24px;
  color: white;

  @media screen and (max-width: 768px) {
    display: block;
    z-index: 999; /* Higher z-index to ensure hamburger button is above content */
  }
`;

const Navbar = () => {
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleLinkClick = () => {
    setOpen(false);
  };

  return (
    <>
      <Nav>
        <Hamburger onClick={handleToggle}>{open ? "✕" : "☰"}</Hamburger>
        <br />
        <NavList open={open}>
          <NavItem>
            <NavLink href="/" onClick={handleLinkClick}>
              Home
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/dashboard" onClick={handleLinkClick}>
              Dashboard
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/about" onClick={handleLinkClick}>
              About Us
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="/contact" onClick={handleLinkClick}>
              Contact Us
            </NavLink>
          </NavItem>
        </NavList>
      </Nav>
      <Overlay open={open} onClick={handleToggle} />
    </>
  );
};

export default Navbar;
