import React, { useEffect,useState } from "react";
import SnakeGame from "./SnakeGame";
import "./SnakeGame.css";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import apiUrl  from "../../../config";

const SnakeMain = () => {
	const navigate = useNavigate();
	const { userID } = useParams();
	const [highScore, setHighScore] = useState(null);

	useEffect(() => {
		const token = localStorage.getItem("jwt");
		if (!token) {
			// Handle the case where the token is not present
			console.log("JWT token not found in localStorage");
			// Navigate to the register page
			navigate("/register");
			return;
		}
		try{
			const fetchHighScore = async () => {
			const response = await axios.get(`${apiUrl}/Snakegame/${userID}/details`);
			console.log(response.data.highScore);
			setHighScore(response.data.highScore);
			};
			fetchHighScore();
		}catch(err){
			console.log(err)
		}
	}, []);
	return (
		<div>
			<div className="textWrapper">
				<p id="instructions">Use the arrow keys or W/A/S/D to play</p>
				<p className="subTitle">Pround to be the snakians</p>
			</div>
			{highScore !== null && <SnakeGame highScore={highScore} userID={userID} />}
		</div>
	);
};

export default SnakeMain;
