import React from "react";
import styled from "styled-components";

import logoImage from "./logo.jpg"; // Make sure to provide the correct path to your logo image

const Footer = () => {
  return (
    <FooterContainer>
      <Content>
        <LeftSection>
          <Logo>
            <LogoImage src={logoImage} alt="MyLogo" />
          </Logo>
          <CopyRight>
            &copy; 2023 Skill Swap Company. All rights reserved.
          </CopyRight>
        </LeftSection>
        <RightSection>
          <SocialMedia>
            <SocialLink
              href="https://www.facebook.com/profile.php?id=61550428124489"
              target="_blank"
            >
              Facebook
            </SocialLink>
            <SocialLink href="https://twitter.com/v_bragadeesh" target="_blank">
              Twitter
            </SocialLink>
            <SocialLink
              href="https://www.instagram.com/skillswap07/"
              target="_blank"
            >
              Instagram
            </SocialLink>
          </SocialMedia>
          <ContactInfo>
            <ContactLink href="mailto:skillswap934@gmail.com">Mail</ContactLink>
          </ContactInfo>
        </RightSection>
      </Content>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 1rem 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    margin-left: 10px;
  }
`;

const LeftSection = styled.div`
  margin-bottom: 1rem;
  margin-left: 10px;
`;

const Logo = styled.div`
  margin-bottom: 0.5rem;
  margin-left: 10px;
`;

const LogoImage = styled.img`
  max-width: 80px;
`;

const CopyRight = styled.p`
  font-size: 0.8rem;
  margin: 0;
`;

const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: flex-end;
  }
`;

const SocialMedia = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-right: 1rem;
  }
`;

const SocialLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 0.9rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    margin-right: 1rem;
    margin-bottom: 0;
  }
`;

const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    flex-direction: row;
    margin-right: 10px;
  }
`;

const ContactLink = styled.a`
  color: #fff;
  text-decoration: none;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;

  &:hover {
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    margin-bottom: 0;
  }
`;

export default Footer;
