import React, { useEffect, useState } from "react";
import { useParams, Link,useNavigate } from "react-router-dom";
import axios from "axios";
import styled from "styled-components";
import API_URL from "../../config";


const ImplementDetails = () => {
  const { userID, postID, userName } = useParams();
  const [implement, setImplement] = useState({});
  const [description2, setDescription2] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isJoined, setIsJoined] = useState(false);
  const [currentOwner, setCurrentOwner] = useState();
  const [currentTitle, setCurrentTitle] = useState();
  const apiUrl = API_URL;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${apiUrl}/implements/${userID}/${postID}`
        );
        setImplement(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userID, postID]);

  const openPopup = (owner, title) => {
    setIsPopupOpen(true);
    setCurrentOwner(owner);
    setCurrentTitle(title);
  };

  const handleJoin = async (owner, title, userName, id) => {
    try {
      const response = await axios.post(`${apiUrl}/implements/${id}/join`, {
        userId: userID,
        description: description2,
        title: title,
        userName: userName,
      });
      setIsJoined(true);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const handleDescriptionChange = (event) => {
    setDescription2(event.target.value);
  };

  const acceptUser = async (otheruserId, title, id) => {
    try {
      const response = await axios.post(`${apiUrl}/implements/${id}/accept`, {
        otheruserId: otheruserId,
        title: title,
      });
      window.location.reload();
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  const rejectUser = async (otheruserId, title, id) => {
    try {
      const response = await axios.post(`${apiUrl}/implements/${id}/reject`, {
        otheruserId: otheruserId,
        title: title,
      });
      window.location.reload();
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };

  return (
    <Container>
      <BackButton onClick={() => navigate(-1)}>Back</BackButton>
      <h1>Implement Details</h1>
      {implement && !(implement.owner === userID) && implement.details && (
        <Detail key={implement.details._id}>
          <Title>Title: {implement.details.title}</Title>
          <Description>
            Description: {implement.details.description}
          </Description>
          {isJoined && currentTitle === implement.details.title ? (
            <Status>Status: Request Sent</Status>
          ) : (
            <>
              {implement.details.acceptedUsers &&
                implement.details.acceptedUsers.map(
                  (user) =>
                    user.user === userID && (
                      <div key={user.user}>
                        <User>{user.userName}</User>
                        <Status>Status: {user.status}</Status>
                      </div>
                    )
                )}

              {console.log("accepted users", implement.details.acceptedUsers)}
              {console.log("user id", userID)}
              {!implement.details.acceptedUsers.some(
                (user) => user.user === userID
              ) && (
                <JoinButton
                  onClick={() =>
                    openPopup(implement.owner, implement.details.title)
                  }
                >
                  Join
                </JoinButton>
              )}
            </>
          )}
        </Detail>
      )}
      {isPopupOpen && (
        <PopupContainer>
          <TextArea
            type="text"
            placeholder="Enter Your Description"
            value={description2}
            onChange={handleDescriptionChange}
          />
          <Button
            onClick={() => {
              if (description2.trim() !== "") {
                handleJoin(currentOwner, currentTitle, userName, postID);
                setIsPopupOpen(false);
                setDescription2("");
              } else {
                alert("Please provide a reason to join.");
              }
            }}
          >
            Submit
          </Button>
          <Button onClick={() => setIsPopupOpen(false)}>Close</Button>
        </PopupContainer>
      )}
    </Container>
  );
};

export default ImplementDetails;
const Container = styled.div`
  padding: 20px;
`;

const TextArea = styled.textarea`
  width: 70%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 50%;
`;

const Detail = styled.div`
  margin-top: 20px;
`;

const Title = styled.h3`
  margin-bottom: 10px;
`;

const Description = styled.p`
  margin-bottom: 10px;
`;

const Status = styled.p`
  font-style: italic;
`;
const User = styled.h4`
  margin-bottom: 5px;
  color: #333;
`;

const ActionButtons = styled.div`
  margin-top: 10px;
`;

const AcceptButton = styled.button`
  margin-right: 10px;
  padding: 5px 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const RejectButton = styled.button`
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #c82333;
  }
`;

const Input1 = styled.input`
  width: 80%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  margin-bottom: 10px;
`;

const JoinRequestSent = styled.p`
  color: #007bff;
`;

const RequestAccepted = styled.p`
  color: #28a745;
`;

const RequestRejected = styled.p`
  color: #dc3545;
`;
const Input = styled.input`
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;
const JoinButton = styled.button`
  padding: 5px 10px;
  background-color: #28a745;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const InputP = styled.textarea`
  width: 50%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 30%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Button = styled.button`
  padding: 8px 20px;
  margin: 0 10px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  &:hover {
    background-color: #0056b3;
  }
`;

const BackButton = styled(Link)`
  padding: 8px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background-color: #0056b3;
  }
`;
