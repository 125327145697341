import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link, useParams } from "react-router-dom";
import Navbar from "../../components/navbar";
import styled, { keyframes } from "styled-components";
import API_URL from "../../config";
const Notifications = () => {
	const [notifications, setNotifications] = useState([]); // Use useState instead of useEffect
	const [notifiers, setNotifiers] = useState([]); // Use useState instead of useEffect
	const [userID, setUserID] = useState({}); // Use useState instead of useEffect
	const navigate = useNavigate();
	const apiUrl = API_URL;
	const { userId } = useParams();
	const [loading, setLoading] = useState(true);
	const [openPopup, setOpenPopup] = useState(false);
	const [uid, setUid] = useState("");
	const [fid, setFid] = useState("");

	const fetchNotifications = async () => {
		// Wrap the asynchronous function inside useEffect
		setLoading(true);
		try {
			// Retrieve the JWT token from localStorage
			// const token = localStorage.getItem("jwt");
			// if (!token) {
			// 	// Handle the case where the token is not present
			// 	console.error("JWT token not found in localStorage");
			// 	navigate("/register");
			// 	return;
			// }

			// Make a request to the backend with the JWT token in the Authorization header
			const response = await axios.get(
				`${apiUrl}/notifications?userId=${userId}`
			);

			if (response.data.error !== "") {
				navigate("/p");
			}
			setNotifications(response.data.notifications);
			setNotifiers(response.data.notifiers);
			setUserID(response.data._id);
			setLoading(false);
		} catch (error) {
			console.error("Failed to fetch user details:", error);
		}
	};
	useEffect(() => {
		fetchNotifications();
	}, [navigate]); // Add navigate to the dependency array

	const acceptUser = async (userID, feedID) => {
		console.log("fid ", feedID, " uid ", userID);
		try {
			// Make a request to the backend with the JWT token in the Authorization header
			const PLNotification = notifiers.map((notifier, index) => {
				if (notifier._id === feedID) {
					console.log("feedddd-------------=========", notifier._id);
					return notifications[index];
				}
			});
			console.log("pllll", PLNotification);

			const response = await axios.post(`${apiUrl}/accept-swap`, {
				feedID,
				userID,
				learnSkill: PLNotification[0].learnSkill,
				preachSkill: PLNotification[0].preachSkill,
			});
			if (response.data.error === "") {
				console.log("swap grant successfully sent from front end ");
			} else {
				console.log("error in sending swap grant from front end");
			}

			sendNotification(
				feedID,
				userID,
				"Your swap request has been accepted",
				"swap-accept"
			);
		} catch (error) {
			console.error("Failed inin swap grant :", error);
		}
		fetchNotifications();
	};
	const rejectUser = async (userID, feedID) => {
		console.log("fid ", feedID, " uid ", userID);
		try {
			// Make a request to the backend with the JWT token in the Authorization header
			const response = await axios.post(`${apiUrl}/reject-swap`, {
				feedID,
				userID,
			});
			if (response.data.error === "") {
				console.log("swap reject successfully sent from front end ");
			} else {
				console.log("error in sending swap reject from front end");
			}
			sendNotification(
				feedID,
				userID,
				"Your swap request has been rejected",
				"swap-reject"
			);
			setOpenPopup(false);
		} catch (error) {
			console.error("Failed inin swap reject :", error);
		}
		fetchNotifications();
	};

	const sendNotification = async (
		otheruserId,
		currentUserId,
		message,
		type
	) => {
		try {
			const response = await axios.post(`${apiUrl}/notifications`, {
				userId: otheruserId,
				from_user: currentUserId,
				message: message,
				type: type,
			});
			console.log("Response from server from notification:", response.data);
		} catch (error) {
			console.error("Error occurred:", error);
		}
	};

	const acceptPayUser = async (userID, feedID, skillCoins) => {
		console.log("fid ", feedID, " uid ", userID);
		try {
			// Make a request to the backend with the JWT token in the Authorization header
			const PLNotification = notifiers.map((notifier, index) => {
				if (notifier._id === feedID) {
					console.log("feedddd-------------=========", notifier._id);
					return notifications[index];
				}
			});
			console.log("pllll", PLNotification);

			const response = await axios.post(`${apiUrl}/accept-pay`, {
				feedID,
				userID,
				learnSkill: PLNotification[0].learnSkill,
				skillCoins: skillCoins,
			});
			if (response.data.error === "") {
				console.log("swap grant successfully sent from front end ");
			} else {
				console.log("error in sending swap grant from front end");
			}
		} catch (error) {
			console.error("Failed inin swap grant :", error);
		}
		fetchNotifications();
	};
	const handleFinalPay = async (userID, feedID, learnSkill, skillCoins) => {
		console.log("Clickedddd handle final payyy");

		try {
			const response = await axios.post(`${apiUrl}/detect-skill-coins`, {
				userID: userID,
				feedID: feedID,
				skillCoins: skillCoins,
			});
			if (response.data.error === "") {
				console.log("skill coins updated successfully ");
				alert(
					`Payment Successful!! ${skillCoins} has been detected from your account`
				);

				const chat_response = await axios.post(`${apiUrl}/accept-swap`, {
					userID: userID,
					feedID: feedID,
					learnSkill: learnSkill,
					preachSkill: null,
				});
			} else if (response.data.error !== "") {
				alert(response.data.error);
			} else {
				console.log("error in sending swap grant from front end");
			}
		} catch (error) {
			console.error("Failed inin swap grant :", error);
		}
		fetchNotifications();
	};

	const handleOk = async (from_user, type) => {
		console.log("Ok clicked");
		const response = await axios.post(`${apiUrl}/notifications/remove`, {
			userID: userID,
			from_user: from_user,
			type: type,
		});
		console.log("ok----response", response);
		fetchNotifications();
	};

	const handleSwapReject = async (userID, feedID) => {
		setOpenPopup(true);
		setUid(userID);
		setFid(feedID);
	};

	return loading ? (
		<div>
			<CustomLoader>
				<LoaderSpinner />
			</CustomLoader>
		</div>
	) : (
		<NotificationsContainer>
			<BackButton onClick={() => navigate(-1)}>Back</BackButton>

			<NotificationsTitle>Notifications</NotificationsTitle>
			<NotificationsList>
				{notifications.length === 0 ? (
					<NotificationItem>
						<p>No notifications</p>
					</NotificationItem>
				) : (
					notifications.map((notification, index) => (
						<NotificationItem key={index}>
							{notification.type === "swap-request" && (
								<>
									<p>
										<b>TYPE: </b>
										{notification.type}
									</p>
									<p>
										<b>NAME: </b>
										{notifiers[index].name}
									</p>
									<p>
										<b>PREACH SKILL: </b> {notification.preachSkill.skill} [
										{notification.preachSkill.category}]
									</p>
									<p>
										<b>LEARN SKILL: </b>
										{notification.learnSkill.skill} [
										{notification.learnSkill.category}]
									</p>
									<NotificationButtonGroup>
										<AcceptButton
											onClick={() => acceptUser(userID, notifiers[index]._id)}
										>
											Accept
										</AcceptButton>
										<RejectButton
											onClick={() =>
												handleSwapReject(userID, notifiers[index]._id)
											}
										>
											Reject
										</RejectButton>
										<ProfileButton
											to={`/user/${notifiers[index]._id}/${userID}/gender`}
										>
											View Profile
										</ProfileButton>
									</NotificationButtonGroup>
								</>
							)}
							{/* Add similar sections for other notification types */}
							{notification.type === "pay-request" && notification.learnSkill && (
								<NotificationContainer>
									<NotificationHeader>Pay Request</NotificationHeader>
									<NotificationDetails>
										<p>
											<b>NAME: </b>
											{notifiers[index].name}
										</p>
										<p>
											PREACH SKILL: {notification.learnSkill.skill} [
											{notification.learnSkill.category}]
										</p>
										<p>
											{notifiers[index].name} is will to pay{" "}
											{notification.skillCoins} to learn{" "}
											{notification.learnSkill.skill} from you
										</p>
										<ButtonContainer>
											<AcceptButton
												onClick={() =>
													acceptPayUser(
														userID,
														notifiers[index]._id,
														notification.skillCoins
													)
												}
											>
												Accept
											</AcceptButton>
											<RejectButton
												onClick={() => rejectUser(userID, notifiers[index]._id)}
											>
												Reject
											</RejectButton>
											<StyledLink
												to={`/user/${notifiers[index]._id}/${userID}/gender`}
											>
												View Profile
											</StyledLink>
										</ButtonContainer>
									</NotificationDetails>
								</NotificationContainer>
							)}

							{notification.type === "Accepted-Pay" && notification.learnSkill && (
								<NotificationContainer>
									<NotificationHeader>Accepted Pay Request</NotificationHeader>
									<NotificationDetails>
										<p>
											LEARN SKILL: {notification.learnSkill.skill} [
											{notification.learnSkill.category}]
										</p>
										<p>
											Payment of {notification.skillCoins} skillCoins has been
											accepted
										</p>
										<p>
											Please Click the Pay button to finalize the payment
											process
										</p>
										<ButtonContainer>
											<AcceptButton
												onClick={() =>
													handleFinalPay(
														userID,
														notifiers[index]._id,
														notification.learnSkill,
														notification.skillCoins
													)
												}
											>
												Pay
											</AcceptButton>
											<RejectButton
												onClick={() => rejectUser(userID, notifiers[index]._id)}
											>
												Reject
											</RejectButton>
										</ButtonContainer>
									</NotificationDetails>
								</NotificationContainer>
							)}
							{notification.type === "rejectImplementUser" && (
								<NotificationContainer>
									<NotificationHeader>Rejected</NotificationHeader>
									<NotificationDetails>
										<p>
											Your request for the project join request with{" "}
											{notifiers[index].name} has been rejected
										</p>
										<br></br>
										<h5>Reason</h5>
										<p>{notification.message}</p>
										<ButtonContainer>
											<button
												onClick={() => {
													handleOk(notifiers[index]._id, "rejectImplementUser");
												}}
											>
												Ok
											</button>
										</ButtonContainer>
									</NotificationDetails>
								</NotificationContainer>
							)}

							{notification.type === "acceptImplementUser" && (
								<NotificationContainer>
									<NotificationHeader>Accepted</NotificationHeader>
									<NotificationDetails>
										<p>
											Your request for the project join request with{" "}
											{notifiers[index].name} has been accepted
										</p>
										<p>{notification.message}</p>
										<ButtonContainer>
											<button
												onClick={() => {
													handleOk(notifiers[index]._id, "acceptImplementUser");
												}}
											>
												Ok
											</button>
										</ButtonContainer>
									</NotificationDetails>
								</NotificationContainer>
							)}

							{notification.type === "swap-accept" && (
								<NotificationContainer>
									<NotificationHeader>Accepted</NotificationHeader>
									<NotificationDetails>
										<p>
											<b>{notification.message}</b>
										</p>

										<p>
											Your swap request with {notifiers[index].name} has been
											accepted successfully and The Chat Portal is enabled for
											you to communicate with each other
										</p>
										<ButtonContainer>
											<ProfileButton
												onClick={() => {
													handleOk(notifiers[index]._id, "swap-accept");
												}}
											>
												Ok
											</ProfileButton>
										</ButtonContainer>
									</NotificationDetails>
								</NotificationContainer>
							)}

							{notification.type === "swap-reject" && (
								<NotificationContainer>
									<NotificationHeader>Rejected</NotificationHeader>
									<NotificationDetails>
										<p>
											<b>{notification.message}</b>
										</p>

										<p>
											Your swap request with {notifiers[index].name} has been
											rejected
										</p>
										<ButtonContainer>
											<ProfileButton
												onClick={() => {
													handleOk(notifiers[index]._id, "swap-reject");
												}}
											>
												Ok
											</ProfileButton>
										</ButtonContainer>
									</NotificationDetails>
								</NotificationContainer>
							)}

							{notification.type === "success-Finish" && (
								<NotificationContainer>
									<NotificationHeader>Success</NotificationHeader>
									<NotificationDetails>
										<p>
											<b>{notification.message}</b>
										</p>
										<p>
											Your Learning with {notifiers[index].name} has been
											successfully completed
										</p>
										<ButtonContainer>
											<ProfileButton
												onClick={() => {
													handleOk(notifiers[index]._id, "success-Finish");
												}}
											>
												Ok
											</ProfileButton>
										</ButtonContainer>
									</NotificationDetails>
								</NotificationContainer>
							)}
						</NotificationItem>
					))
				)}
			</NotificationsList>
			{openPopup && (
				<PopUpContainer>
					<PopUpContent>
						<PopUpHeader>
							You can never swap with him if You rejected as we wiil block the
							user from your view
						</PopUpHeader>
						<ButtonContainer>
							<RejectButton onClick={() => rejectUser(uid, fid)}>
								Confirm Reject
							</RejectButton>
							<ProfileButton onClick={() => setOpenPopup(false)}>
								Close
							</ProfileButton>
						</ButtonContainer>
					</PopUpContent>
				</PopUpContainer>
			)}
		</NotificationsContainer>
	);
};

const PopUpContainer = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
`;

const PopUpContent = styled.div`
	background-color: white;
	padding: 20px;
	border-radius: 5px;
`;

const PopUpHeader = styled.h2`
	font-size: 24px;
	margin-bottom: 20px;
`;

const CloseButton = styled.button`
	padding: 5px 10px;
	background-color: #dc3545;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
	&:hover {
		background-color: #c82333;
	}
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
const ProfileButton = styled(Link)`
	padding: 5px 10px; /* Decreased padding */
	background-color: #007bff;
	color: white;
	text-decoration: none;
	border-radius: 5px;
	transition: background-color 0.3s;
	margin-left: 10px;

	&:hover {
		background-color: #0056b3;
	}
`;

const NotificationsContainer = styled.div`
	max-width: 600px;
	margin: 0 auto;
	padding: 20px;
	animation: ${fadeIn} 0.5s ease-in-out;
`;

//loader
const CustomLoader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
`;

const LoaderSpinner = styled.div`
	border: 4px solid rgba(0, 0, 0, 0.1);
	border-top: 4px solid #3498db;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	animation: spin 1s linear infinite;

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
`;

const NotificationsTitle = styled.h1`
	font-size: 24px;
	margin-bottom: 20px;
`;

const NotificationsList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;
`;

const NotificationItem = styled.li`
	padding: 10px 0;
	border-bottom: 1px solid #e0e0e0;
`;

const NotificationButtonGroup = styled.div`
	display: flex;
	gap: 10px;
	margin-top: 10px;
`;

const NotificationButton = styled.button`
	padding: 5px 10px;
	background-color: #007bff;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;

	&:hover {
		background-color: #0056b3;
	}
`;

const NotificationContainer = styled.div`
	background-color: #f5f5f5;
	padding: 10px;
	border-radius: 5px;
	margin: 10px 0;
`;

const NotificationHeader = styled.h3`
	font-size: 18px;
	margin-bottom: 5px;
`;

const NotificationDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
`;

const ButtonContainer = styled.div`
	margin-top: 10px;
	display: flex;
	gap: 10px;
`;
const StyledLink = styled(Link)`
	text-decoration: none;
	color: #3498db;
	padding: 5px 10px;
	border-radius: 5px;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #f0f0f0;
	}
`;
const RejectButton = styled.button`
	margin-left: 5px;
	padding: 5px 10px;
	background-color: #dc3545;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background-color: #c82333;
	}
`;
const AcceptButton = styled.button`
	margin-right: 10px;
	padding: 5px 10px;
	background-color: #28a745;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;
	&:hover {
		background-color: #218838;
	}
`;

const BackButton = styled(Link)`
	display: inline-block;
	background-color: #2e4052;
	color: white;
	padding: 10px 20px;
	text-decoration: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s ease;

	&:hover {
		background-color: #34495e;
	}
`;

export default Notifications;
