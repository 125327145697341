const API_URL = "https://skills-back.vercel.app/api";


// // Under development
// const API_URL = "http://localhost:5000/api";

export const SOCKET_URL="https://skill-swap-socket.vercel.app";

// export const SOCKET_URL = "http://localhost:8800";

export const SKILL_COIN_REGISTER = 5;
export const SKILL_COIN_IMPLEMENT_POST = 1;
export const SKILL_COIN_GAME = 1;
export const SKILL_COIN_IMPLEMENT_ACCEPT = 5;
export const SKILL_COIN_SWAP_ACCEPT = 5;
export const SKILL_COIN_SWAP_FINISH = 10;
export const SKILL_COIN_DAILY_LOGIN = 1;

export default API_URL;
