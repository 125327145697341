import React, { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 20px;

	@media (max-width: 768px) {
		flex-direction: column;
		align-items: stretch;
	}
`;

const Input = styled.input`
	padding: 8px;
	margin-right: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	flex: 1;

	@media (max-width: 768px) {
		margin-right: 0;
		margin-bottom: 10px;
	}
`;

const Select = styled.select`
	padding: 8px;
	margin-right: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;

	@media (max-width: 768px) {
		margin-right: 0;
		margin-bottom: 10px;
	}
`;

const Button = styled.button`
	padding: 8px 16px;
	background-color: #007bff;
	color: #fff;
	border: none;
	border-radius: 4px;
	cursor: pointer;

	@media (max-width: 768px) {
		width: 100%;
	}
`;

const SearchComponent = ({ onSearch }) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [selectedFilter, setSelectedFilter] = useState("name");

	const handleSearch = () => {
		onSearch(searchQuery, selectedFilter);
	};

	const handleChange = (value) => {
		if (value === "") {
			setSearchQuery(value);
			onSearch(value, selectedFilter);
		}
		setSearchQuery(value);
	};

	return (
		<Container>
			<Input
				type="text"
				value={searchQuery}
				onChange={(e) => handleChange(e.target.value)}
				placeholder="Enter your search query"
			/>
			<Select
				value={selectedFilter}
				onChange={(e) => setSelectedFilter(e.target.value)}
			>
				<option value="name">Name</option>
				<option value="skillsKnown">Skill</option>
				<option value="languages">Language</option>
			</Select>
			<Button onClick={handleSearch}>Search</Button>
		</Container>
	);
};

export default SearchComponent;
